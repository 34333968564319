// src/components/HelpSection.js
import React from 'react';
import './HelpSection.scss';

const HelpSection = () => {
  return (
    <section className="help-section">
      <h2 className="help-section-title">How can I help you?</h2>
      <div className="help-cards">
        <div className="help-card">
          <a href="https://irfanahmadein.com/tag/engineering-management/" target="_blank" rel="noopener noreferrer">
            <div className="help-card-emoji">👨🏾‍💻</div>
            <h3 className="help-card-title"><strong>Tech Leadership </strong></h3>
            <p className="help-card-description">Get Insights to become a more efficient engineering leader .</p>
          </a>
        </div>
        <div className="help-card">
          <a href="https://irfanahmadein.com/tag/test-automation/" target="_blank" rel="noopener noreferrer">
            <div className="help-card-emoji">👷🏾‍♂️</div>
            <h3 className="help-card-title"><strong>Test Automation </strong></h3>
            <p className="help-card-description">Get Learnings on reliable and effective test automation .</p>
          </a>
        </div>
        <div className="help-card">
          <a href="https://checkops.com" target="_blank" rel="noopener noreferrer">
            <div className="help-card-emoji">👨🏾‍🔧</div>
            <h3 className="help-card-title"><strong>Tech Tools</strong></h3>
            <p className="help-card-description">Find the right automation tools to be more productive.</p>
          </a>
        </div>
        <div className="help-card">
          <a href="https://irfanahmadein.com/tag/tech-conference/" target="_blank" rel="noopener noreferrer">
            <div className="help-card-emoji">👨🏾‍🏫</div>
            <h3 className="help-card-title"><strong>Tech Talks</strong></h3>
            <p className="help-card-description">Sharing my tech talks , teachings experiences here.</p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HelpSection;